<template>

  <Header />

  <main>
    <div class="container">

      <div class="upper-section">
        <h2>Patches</h2>
        <router-link
          class="button small"
          :class="{ 'disabled': isAPIReadOnly }"
          :to="{ name: isAPIReadOnly ? null : 'PatchCreate', params: { referrerLink: 'patches-header' } }"
        >
          Create Patch
        </router-link>
        <span class="flex-grow" />
      </div>

      <Tabs>
        <template #tabs>
          <router-link
            class="tab"
            to="/patches/overview"
          >
            Overview
          </router-link>
          <!--
            @NOTE: we apply the router-link-exact-active class manually here
            so that the tab is highlighted even when the optional patchEventId
            route param is specified (for whatever reason Vue Router doesn't
            consider that an exact match??)
          -->
          <router-link
            class="tab"
            to="/patches/history"
            :class="{ 'router-link-exact-active': $route.name === 'PatchHistory' }"
          >
            History
          </router-link>
        </template>
      </Tabs>

      <router-view />

    </div>
  </main>

  <Footer />

</template>

<script>

  import { mapState } from 'vuex'

  import Tabs from '@/components/page/Tabs.vue'
  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'

  export default {
    components: {
      Tabs,
      Header,
      Footer,
    },
    computed: {
      ...mapState('app', ['isAPIReadOnly']),
    },
  }

</script>

<style lang="stylus" scoped>

  main
    @apply bg-white

    min-height: calc(100% - 128px)

  .container
    @apply p-4
    @apply pb-16
    @apply mx-auto

  .upper-section
    @apply my-6

    @apply flex
    @apply gap-8
    @apply items-end

    +breakpoint(sm)
      @apply mt-12

    h2
      @apply mb-0

    a
      @apply flex-shrink-0

</style>
