<template>
  <div class="tabs">
    <slot name="tabs" />
  </div>
  <div class="tab-content">
    <slot name="tab-content" />
  </div>
</template>

<script>

  export default {}

</script>

<style lang="stylus" scoped>

  .tabs
    @apply z-0
    @apply h-12
    @apply mb-6
    @apply w-full
    @apply relative
    @apply overflow-x-auto

    @apply flex
    @apply items-start

    scroll-behavior: smooth

    &::after
      @apply block
      @apply w-full
      @apply h-full
      @apply border-b-2
      @apply border-gray-400

      content: ''

    :slotted(.tab)
      @apply px-4
      @apply h-full
      @apply text-sm
      @apply font-bold
      @apply text-center
      @apply rounded-none
      @apply whitespace-nowrap

      @apply border-b-2
      @apply text-gray-800
      @apply border-gray-400

      @apply flex
      @apply items-center

      +breakpoint(sm)
        @apply px-6
        @apply text-xl

      &:hover
        @apply shadow-none
        @apply no-underline
        @apply bg-purple-200
        @apply text-gray-1000
        @apply border-gray-600

      &:focus-visible
        @apply border-primary-500
        box-shadow: inset 0 0 0 2px theme('colors.primary.300'), inset 0 0 0 4px rgba(255, 138, 164, 0.3)

      &.active
      &.router-link-exact-active
        @apply text-primary-500
        @apply border-primary-500

</style>
